<template>

  <!-- {{user}} -->
  <div class="col-lg-9 col-md-9">
    <div class="iq-edit-list-data">
      <div class="tab-content">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('label.addBank')}}</h4>
          </template>
          <template v-slot:body>
            <form>
              <!-- 我們建議使用移動設備或帶有攝像頭的設備來完成此驗證步驟 -->
              <div class="row align-items-center">
                <div class="form-group col-sm-6">
                  <label for="fname" class="form-label">{{$t('label.accountName')}}</label>
                  <input type="text" v-model="form.bank_account_name" class="form-control" id="bank_account_name" name="bank_account_name" :placeholder="$t('label.accountNamePlaceholder')"
                    @blur="v$.$touch()" :class="{ 'is-invalid': v$.form.bank_account_name.$error }" />
                  <span v-if="v$.form.bank_account_name.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <div class="form-group col-sm-6">
                  <label for="lname" class="form-label">{{$t('label.bankSn')}}</label>
                  <input type="text" v-model="form.bank_sn" class="form-control" id="bank_sn" name="bank_sn" :placeholder="$t('label.bankSnPlaceholder')" @blur="v$.$touch()"
                    :class="{ 'is-invalid': v$.form.bank_sn.$error }" />
                  <span v-if="v$.form.bank_sn.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
                <div class="form-group col-sm-6">
                  <label for="uname" class="form-label">{{$t('label.bankAccount')}}</label>
                  <input type="text" v-model="form.bank_account" class="form-control" id="bank_account" name="bank_account" :placeholder="$t('label.bankAccountPlaceholder')" @blur="v$.$touch()"
                    :class="{ 'is-invalid': v$.form.bank_account.$error }" />
                  <span v-if="v$.form.bank_account.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>

                <div class="form-group col-sm-6">
                  <label for="dob" class="form-label">{{$t('label.banName')}}</label>
                  <input type="text" v-model="form.bank_name" class="form-control" id="bank_name" name="bank_name" :placeholder="$t('label.bankNamePlaceholder')" @blur="v$.$touch()"
                    :class="{ 'is-invalid': v$.form.bank_name.$error }" />
                  <span v-if="v$.form.bank_name.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button @click="onSubmit()" type="button" class="btn btn-primary me-2">
                  <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                  {{$t('button.submit')}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </div>
    </div>
  </div>

</template>
<script>
import { addBank, getBank } from '@/api/bank'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
export default {
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  data() {
    return {
      commomData: commomData(),
      form: {
        bank_account_name: '',
        bank_sn: '',
        bank_name: '',
        bank_account: ''
      }
    }
  },
  validations() {
    return {
      form: {
        bank_account_name: { required },
        bank_sn: { required, minLength: minLength(3) },
        bank_name: { required },
        bank_account: { required, minLength: minLength(12) }
      }
    }
  },
  created() {
    this.getRow()
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      addBank(this.form)
        .then(res => {
          console.log(res)
          this.$swal.mixin().fire({
            icon: 'success',
            text: res.message
          })
          this.getRow()
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    getRow() {
      getBank()
        .then(res => {
          console.log(res)
          // 是綁定才binding
          if (res.data.is_bank) {
            this.form = res.data
          }
        })
        .catch(err => {
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  }
}
</script>
