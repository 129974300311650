import request from '../utils/request'

// API-F11 - [BankController] bank/create 銀行卡新增&編輯
export function addBank(data) {
  return request({
    url: `/bank/create`,
    method: 'post',
    data
  })
}

// API-F12 - [BankController] bank/detail 銀行卡詳細
export function getBank(params = {}) {
  return request({
    url: `/bank/detail`,
    method: 'get',
    params
  })
}
